export let createCFGroupSchema = {
  WorkIdentifiedObject: {
    IdentifiedObject: {
      name: "",
      description: null,
      enproCode: "",
      names: [],
    },
  },
  code: "",
  status_id: 0,
};
