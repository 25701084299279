import { createCFGroupSchema } from "@/services/references/cFGroup/schemas";

export const parse_cFGroup_to_create = (value) => {
  let data = Object.assign({}, createCFGroupSchema);
  data.code = value.code;
  data.WorkIdentifiedObject.IdentifiedObject.enproCode = value.code;
  data.WorkIdentifiedObject.IdentifiedObject.name = value.name;
  data.status_id = value.statusId;
  return data;
};

export const parse_cFGroup_to_update = (value) => {
  let data = Object.assign({}, value);
  data.status_id = value.status.id;
  data.WorkIdentifiedObject.IdentifiedObject.enproCode = value.code;
  return data;
};
