<template>
  <form @submit.prevent="submit">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="titlePage"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
        />
        <UIButton
          v-show="editable"
          text="Удалить"
          leftIcon="bi-trash"
          @click="deleteButton"
          float
        />
      </div>
    </div>
    <div class="row gap-3" v-if="cFGroup">
      <dl class="col row gap-2">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput
                v-model="cFGroup.WorkIdentifiedObject.IdentifiedObject.name"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Шифр:</dt>
            <dl class="col">
              <EditableInput
                v-model="cFGroup.code"
                :editable="editable"
                required
              />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Статус:</dt>
            <dl class="col">
              <EditableSelect
                :value="lodash.get(cFGroup, 'status.id')"
                @input="(e) => lodash.set(cFGroup, 'status.id', e)"
                :options="status"
                :editable="editable"
                required
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import { API_StatusList } from "@/services/api";
import {
  API_cFGroup_view,
  API_cFGroup_update,
  API_cFGroup_delete,
} from "@/services/references/cFGroup";

export default {
  name: "cFGroupViewEdit",
  components: { UIButton, EditableInput, EditableSelect },
  data() {
    return {
      /**
       * @type cFGroupObject|null
       */
      cFGroup: null,
      status: [],
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return true;
        default:
          return false;
      }
    },
    titlePage: function () {
      if (this.editable) {
        return "Редактирование группы поправочного коэффициента";
      }
      return "Просмотр группы поправочного коэффициента";
    },
  },
  mounted() {
    API_StatusList().then((res) => (this.status = res));
    API_cFGroup_view(parseInt(this.$route.params.id)).then(
      (res) => (this.cFGroup = res)
    );
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/references/technological-maps/cFGroup/${this.$route.params.id}/edit`,
      });
    },
    submit() {
      API_cFGroup_update(parseInt(this.$route.params.id), this.cFGroup).then(
        (res) => {
          this.$router.push({
            path: `/references/technological-maps/cFGroup/${res.id}`,
          });
        }
      );
    },
    deleteButton() {
      API_cFGroup_delete(parseInt(this.$route.params.id)).then(() => {
        this.$router.push({
          path: `/references/technological-maps/cFGroup/`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
