import axios from "@/plugins/axios";
import {
  parse_cFGroup_to_create,
  parse_cFGroup_to_update,
} from "@/services/references/cFGroup/parsers";

/**
 * Список "Групп поправочных коэффициентов"
 * @return {Promise<cFGroupObject[]>}
 */
export const API_cFGroup_list = () => {
  return axios("/cFGroup?perPage=5000", {
    method: "GET",
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Просмотр элемента "Групп поправочных коэффициентов"
 * @param  {number} id
 * @return {Promise<cFGroupObject>}
 */
export const API_cFGroup_view = (id) => {
  return axios(`/cFGroup/${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Обновление элемента "Групп поправочных коэффициентов"
 * @param  {number} id
 * @param {Object} value
 * @return {Promise<cFGroupObject>}
 */
export const API_cFGroup_update = (id, value) => {
  return axios(`/cFGroup/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: parse_cFGroup_to_update(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание элемента "Групп поправочных коэффициентов"
 * @param {Object} value
 * @return {Promise<cFGroupObject>}
 */
export const API_cFGroup_create = (value) => {
  return axios(`/cFGroup/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_cFGroup_to_create(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Удаление элемента "Групп поправочных коэффициентов"
 * @param  {number} id
 * @return {Promise<boolean>}
 */
export const API_cFGroup_delete = (id) => {
  return axios(`/cFGroup/${id}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};
